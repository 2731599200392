@font-face {
  font-family: Roboto;
  src: url('../assets/fonts/Roboto/Roboto-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: Roboto;
  src: url('../assets/fonts/Roboto/Roboto-BoldItalic.ttf');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: Roboto;
  src: url('../assets/fonts/Roboto/Roboto-Light.ttf');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: url('../assets/fonts/Roboto/Roboto-LightItalic.ttf');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: Roboto;
  src: url('../assets/fonts/Roboto/Roboto-Medium.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: url('../assets/fonts/Roboto/Roboto-MediumItalic.ttf');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: Roboto;
  src: url('../assets/fonts/Roboto/Roboto-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Quicksand;
  src: url('../assets/fonts/Quicksand/static/Quicksand-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: Quicksand;
  src: url('../assets/fonts/Quicksand/static/Quicksand-Light.ttf');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: Quicksand;
  src: url('../assets/fonts/Quicksand/static/Quicksand-Medium.ttf');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: Quicksand;
  src: url('../assets/fonts/Quicksand/static/Quicksand-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}


@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/Poppins/Poppins-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/Poppins/Poppins-BoldItalic.ttf');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/Poppins/Poppins-Light.ttf');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/Poppins/Poppins-LightItalic.ttf');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/Poppins/Poppins-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/Poppins/Poppins-SemiBold.ttf');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/Poppins/Poppins-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/Poppins/Poppins-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/Poppins/Poppins-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

/* Montserrat */
@font-face {
  font-family: Montserrat;
  src: url('../assets/fonts/Montserrat/Montserrat-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: Montserrat;
  src: url('../assets/fonts/Montserrat/Montserrat-BoldItalic.ttf');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: Montserrat;
  src: url('../assets/fonts/Montserrat/Montserrat-Light.ttf');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  src: url('../assets/fonts/Montserrat/Montserrat-LightItalic.ttf');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: Montserrat;
  src: url('../assets/fonts/Montserrat/Montserrat-MediumItalic.ttf');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: Montserrat;
  src: url('../assets/fonts/Montserrat/Montserrat-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  src: url('../assets/fonts/Montserrat/Montserrat-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}
