.rbc-overlay {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25) !important;
}

.rbc-overlay .rbc-overlay-header {
  display: none;
}

.react-tiny-popover-container {
  z-index: 999999;
}
